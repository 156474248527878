import {
    Box,
    Heading,
    Container,
    Text,
    Button,
    Stack,
    Icon,
    useColorModeValue,
    createIcon, Link,
    Avatar,
    chakra,
    Flex,
    SimpleGrid, Tabs, TabList, TabPanels, Tab, TabPanel, StackDivider, HStack, Center, Image, useDisclosure, Modal, ModalContent, ModalOverlay, ModalHeader, ModalFooter, ModalBody, ModalCloseButton
  } from '@chakra-ui/react';
  import {FaDiscord, FaGithub} from "react-icons/all";
  import GridBlurredBackdrop from "./purpose"
  import * as React from "react";
  import {UserSignUp} from "../Users/UserSignUp";
  import InfoTabs from './infoTabs'
  import StackEx from './ValueCards'

  export const Home = () => {

      const [isOpen, setIsOpen] = React.useState(false)
      const onClose = () => {
          setIsOpen(false)
      }
      const cancelRef = React.useRef()

      return (
          <>
              <header>
                  <link
                      href="https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap"
                      rel="stylesheet"
                  />
                  <title>Home</title>
              </header>
              <Container maxW={'2xl'} ml='-6rem' mt="-1rem">
                  <Stack
                      alignItems='center'
                      textAlign="center"
                      spacing={{ base: 6, md: 10 }}
                      py={{ base: 10, md: 24 }}

                    >
                      <Heading
                          fontWeight={600}
                          fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                          lineHeight={'110%'}
                          >
                              Join Our Club <br/>
                              <Text as={'span'} color={'green.400'}>
                                  UofT's Software Engineering Club!
                              </Text>
                      </Heading>
                        <Image height='17rem' src="./Homep.png" alt="Github repo" width="95%" boxShadow="0 12px 24px -6px rgba(148, 87, 235, 0.6), 0 6px 12px -3px rgba(148, 87, 235, 0.3)" />
                      </Stack>
                      </Container>

                    <Stack borderRadius="md" boxShadow="0 8px 16px -4px rgba(75, 255, 75, 0.6), 0 4px 8px -2px rgba(75, 238, 75, 0.3)" divider={<StackDivider borderColor='purple.300' />} spacing={7} alignItems='center' ml='49rem' mt='-37rem' width='23rem' height='25rem'>
                      <Stack mt='2rem' direction='column'>
                        <Text fontWeight="bold" color='purple.500' > Click the button below to sign up!</Text>
                        <Button
                          colorScheme="none"
                          bgColor='green.500'

                          rounded="full"
                          px={5}
                          py={5}
                          onClick={() => setIsOpen(true)}
                          _hover={{
                             transition: 'background-color 0.3s ease',
                            bgGradient: 'linear(to-b, purple.500, green.400)',
                            boxShadow: 'xl'
                          }}
                        >
                          Registration Form
                        </Button>
                      </Stack>

                      <Stack alignItems='center' direction='column'>
                        <Text fontWeight="bold" color='purple.500' > Join Us On Our Discord server!</Text>
                        <Button
                          colorScheme="purple"
                          rounded="full"
                          leftIcon={<FaDiscord />}
                          px={10}
                          py={5}
                        >
                          <Link href="https://discord.gg/3rkvqgSVH7">USEC Discord Server</Link>
                        </Button>
                      </Stack>

                       <Stack alignItems='center' direction='column'>
                         <Text fontWeight="bold" color='purple.500' > Check Us Out On GitHub!</Text>
                         <Button
                          bgColor='black'
                          color='white'
                          leftIcon={<FaGithub />}
                          rounded="full"
                          px={14}
                          py={5.5}
                        >
                          <Link href="https://github.com/UofTSE">Visit Our GitHub</Link>
                        </Button>
                       </Stack>

                      </Stack>
                    <Box mx='auto' width='90%' mt='15rem'>
                      <InfoTabs />
                    </Box>

                    <Stack spacing='3rem' mt='6.25rem' pb='7rem'>
                      <Text fontWeight='bold' fontSize='4xl' mx='auto'>Our Values</Text>
                      <StackEx />
                    </Stack>



                      <UserSignUp isOpen={isOpen} onClose={onClose} cancelRef={cancelRef}></UserSignUp>

          </>

      );
  }


