import { Stack, Center, Image, Text, HStack, VStack, Heading, Box} from '@chakra-ui/react'
import React from 'react';

const images = [{image: './Community.png'}, {image: './Collaboration.jpeg'}, {image: './Innovation.png'}]

interface FeatureProps {
  title: string;
  desc: string;
  imagePath: string;
  index: number
}

function Feature({ title, desc, imagePath, index, ...rest }: FeatureProps) {

  const isThirdFeature = index === 2;

  return (
    <Box textAlign='center' display='flex' flexDirection="column" alignItems="center" p={5} width='18rem' height='27rem' shadow='md' borderWidth='1px' {...rest}>
      <Image width={isThirdFeature ? '6rem': '8.5rem'} height='8rem' src={imagePath} alt={`Image for ${title}`} />
      <Heading mt='1rem' fontSize='xl'>{title}</Heading>
      <Text mt={4}>{desc}</Text>
    </Box>
  )
}

export default function StackEx() {
  return (
    <Stack
      justify='center' spacing='5rem' direction='row'>
      <Feature
        title='Community'
        desc='We strive to develop a tight-knit community within our club, encouraging members to engaging with the broader software development community by attending conferences, and participating in community projects.'
        imagePath={images[0].image}
        index={0}
      />
      <Feature
        title='Collaboration'
        desc='We encourage the notions of teamwork and collaboration among members to foster a supportive and inclusive environment. Collaboration is key to solving complex problems and sharing knowledge.'
        imagePath={images[1].image}
        index={1}
      />
      <Feature
        title='Innovation'
        desc='We encourage our members to embrace creativity and innovative thinking, in order to explore new ideas, experiment with different technologies, and contribute to cutting-edge projects.'
        imagePath={images[2].image}
        index={2}
      />
    </Stack>
  )
}