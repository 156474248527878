import * as React from "react"
import {
    Avatar,
    Box,
    Center,
    Flex,
    Image,
    Stack,
    useColorModeValue,
    VStack,
    Text,
    Button,
    Heading,
    HStack,
    Link,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody, Popover
} from "@chakra-ui/react"
import {FaGithub, FaLinkedin} from "react-icons/all";

export const ShaarifCard = () => (
    <Center py={6}>
      <Box
        maxW={'400px'}
        minW={'200px'}
        w={'full'}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}>
        <Image
          h={'120px'}
          w={'full'}
          src={
            'https://media.istockphoto.com/photos/decorative-abstract-fragility-blue-shade-wavy-background-picture-id1398788867?b=1&k=20&m=1398788867&s=170667a&w=0&h=wA4Ldsi6j1owDTMkmKxf6FSrn6tshFxffwNRs71blI8='
          }
          objectFit={'cover'}
        />
        <Flex justify={'center'} mt={-12}>
          <Avatar
            size={'xl'}
            src={
              'https://media.licdn.com/dms/image/D5635AQGYgDFTCBe47g/profile-framedphoto-shrink_400_400/0/1695001837387?e=1697925600&v=beta&t=qObE2d-vnzeXzGCdLhXBfqvPSa8NeDP-gXTq7Y2_Ijo'
            }
            css={{
              border: '2px solid white',
            }}
          />
        </Flex>

        <Box p={6}>
          <Stack spacing={0} align={'center'} mb={5}>
            <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
              Shaarif Ali Syed
            </Heading>
            <Text color={'gray.500'}>Vice-President</Text>
              <Popover>
                  <PopoverTrigger>
                    <Button>Biography</Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>Biography!</PopoverHeader>
                    <PopoverBody>Shaarif Ali Syed is a second-year student specializing in computer science at the University of Toronto, where he is also the vice-president of the UofT Software Engineering Club (USEC). He is a hard worker and never backs down from any task no matter how difficult it is. He has a lot of experience in handling different types of people. </PopoverBody>
                  </PopoverContent>
                </Popover>
          </Stack>

            <HStack>
              <Button colorScheme='linkedin' leftIcon={<FaLinkedin />}>
                <Link href={"https://www.linkedin.com/in/shaarif-ali-syed-20a29a23a/"}>LinkedIn</Link>
              </Button>
              <Button color='BlackAlpha 100' leftIcon={<FaGithub />}>
                <Link href={"https://github.com/ShaarifAliSyed"}>GitHub</Link>
              </Button>
            </HStack>
        </Box>
      </Box>
    </Center>
)
