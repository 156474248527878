import * as React from "react"
import {
    Box,
    VStack, Grid, GridItem, SimpleGrid, Text
} from "@chakra-ui/react"

import {YazanCard} from "./UserCard/YazanCard"
import {ShaarifCard} from "./UserCard/ShaarifCard"
import {ShabitCard} from "./UserCard/ShabitCard"
import {QaisCard} from "./UserCard/QaisCard"
import {MoCard} from "./UserCard/MoCard"
import {AsserCard} from "./UserCard/AsserCard"

export const StaffPage = React.memo(() =>  {

    return (
        <Box  w='100%'>
      <SimpleGrid  columns={[null, 1, 2, 3]} spacing='30px'>
        <YazanCard></YazanCard>
          <ShaarifCard></ShaarifCard>
          <ShabitCard></ShabitCard>
          <QaisCard></QaisCard>
          <AsserCard></AsserCard>
          <MoCard></MoCard>
      </SimpleGrid>

      </Box>
    )
}
)
