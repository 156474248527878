import * as React from "react"
import {
    Avatar,
    Box,
    Center,
    Flex,
    Image,
    Stack,
    useColorModeValue,
    VStack,
    Text,
    Button,
    Heading,
    HStack,
    Link,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody, Popover
} from "@chakra-ui/react"
import {FaGithub, FaLinkedin} from "react-icons/all";

export const ShabitCard = () => (
    <Center py={6}>
      <Box
        maxW={'400px'}
        minW={'200px'}
        w={'full'}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}>
        <Image
          h={'120px'}
          w={'full'}
          src={
            'https://media.istockphoto.com/photos/decorative-abstract-fragility-blue-shade-wavy-background-picture-id1398788867?b=1&k=20&m=1398788867&s=170667a&w=0&h=wA4Ldsi6j1owDTMkmKxf6FSrn6tshFxffwNRs71blI8='
          }
          objectFit={'cover'}
        />
        <Flex justify={'center'} mt={-12}>
          <Avatar
            size={'xl'}
            src={
              'https://media.licdn.com/dms/image/D5603AQGbReiiiFLVSQ/profile-displayphoto-shrink_400_400/0/1664762837904?e=1702512000&v=beta&t=E0_GsiKKdgx49jLrryEvf7P07vjTz6WmmUQ-UedN4wo'
            }
            css={{
              border: '2px solid white',
            }}
          />
        </Flex>

        <Box p={6}>
          <Stack spacing={0} align={'center'} mb={5}>
            <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
              Saabit Zubairi
            </Heading>
            <Text color={'gray.500'}>General Secretary</Text>
              <Popover>
                  <PopoverTrigger>
                    <Button>Biography</Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>Biography!</PopoverHeader>
                    <PopoverBody>""</PopoverBody>
                  </PopoverContent>
                </Popover>
          </Stack>

            <HStack>
              <Button colorScheme='linkedin' leftIcon={<FaLinkedin />}>
                <Link href={"https://www.linkedin.com/in/saabit-zubairi-6a3932216/"}>LinkedIn</Link>
              </Button>
              <Button color='BlackAlpha 100' leftIcon={<FaGithub />}>
                <Link href={"https://github.com/Sabsterrexx"}>GitHub</Link>
              </Button>
            </HStack>
        </Box>
      </Box>
    </Center>
)
