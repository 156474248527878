import React, {useState} from 'react';
import {Box, Text, VStack, Heading, Button, Container, Spacer} from '@chakra-ui/react';

const CarbonTrack = () => {

    const [showEmail, setShowEmail] = useState(false);
    const email = 'yazan@armoush.ca';

    const handleContactClick = () => {
        setShowEmail(true);
    };

    return (
        <Container maxW="container.xl" p={8}>
            <VStack spacing={8} align="center">
                <Heading as="h1" size="2xl">
                    Support Page
                </Heading>

                <Box p={4} borderWidth={1} borderRadius="md" shadow="md" w="100%" maxW="xl">
                    <Text>
                        Welcome to our support page! If you have any questions or issues, please feel free to contact us.
                    </Text>
                    <Spacer height={10}/>

                    <Box>
                        {showEmail ? (
                            <p>Email: {email}</p>
                        ) : (
                            <Button onClick={handleContactClick} colorScheme="teal">
                                Show Email
                            </Button>
                        )}
                    </Box>
                </Box>
            </VStack>
        </Container>
    );
};

export default CarbonTrack;
