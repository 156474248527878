import { Tabs, Text, Image, TabList, Stack, List, ListItem, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react'
import React, { useState } from 'react';
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
} from '@chakra-ui/react'


export default function InfoTabs() {
  const [tabIndex, setTabIndex] = useState(0)

  const handleSliderChange = (value: number) => {
    setTabIndex(value);}

  const handleTabsChange = (index: number) => {
    setTabIndex(index)
  }

  return (
    <Stack spacing={59.5} >
      <Text fontWeight='bold' fontSize='4xl' mx='auto'>Why Join USEC?</Text>
      <Slider
        colorScheme='purple'
        aria-label="slider-ex-1"
        value={tabIndex}
        onChange={handleSliderChange}
        min={0}
        max={2}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb boxSize={6} />
      </Slider>

      <Tabs variant='soft-rounded' isFitted index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          <Tab  _hover={{ bg: 'purple.400', color: 'black', boxShadow: '0 0 0 2px black, 0 0 8px rgba(0, 0, 0, 0.6), 0 0 8px 2px purple, inset 0 0 8px rgba(0, 0, 0, 0.6), inset 0 0 8px 2px purple',
                        }}
                _selected={{bg: 'purple.500', color: 'black' }}>Our Purpose</Tab>
          <Tab _hover={{ bg: 'purple.400', color: 'black', boxShadow: '0 0 0 2px black, 0 0 8px rgba(0, 0, 0, 0.6), 0 0 8px 2px purple, inset 0 0 8px rgba(0, 0, 0, 0.6), inset 0 0 8px 2px purple',
                        }}
                _selected={{bg: 'purple.500', color: 'black' }}>Career Development</Tab>
          <Tab _hover={{ bg: 'purple.400', color: 'black', boxShadow: '0 0 0 2px black, 0 0 8px rgba(0, 0, 0, 0.6), 0 0 8px 2px purple, inset 0 0 8px rgba(0, 0, 0, 0.6), inset 0 0 8px 2px purple',
                        }}
                _selected={{bg: 'purple.500', color: 'black' }}>Our Book Club</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <List styleType="disc" fontSize='1xl'>
                <Stack width='30rem' mt='2rem' spacing={4}>
                <ListItem>
                  Our main purpose is to provide opportunities for students to connect with peers by incorporating and regularly holding networking events in order to facilitate these connections.
                </ListItem>
                <ListItem>
                  We highly encourage creativity and entrepreneurship by supporting members in developing their ideas or projects into startups through collaboration.
                </ListItem>
                <ListItem>
                  We want to give members the chance to apply their skills to real-world problems and build a portfolio.
                </ListItem>
                </Stack>
              </List>
              <Image mt='-15.5rem' ml='37rem' width='19rem' height='17rem' src='./Purpose.png' />
          </TabPanel>
          <TabPanel>
            <List styleType="disc" fontSize='1xl'>
                <Stack width='30rem' mt='2rem' spacing={4}>
                <ListItem>
                   USEC will have tools to help members find a career they like in software engineering. For example, USEC will have tools illustrating how typical software engineering interviews are executed.
                </ListItem>
                <ListItem>
                   USEC will also have resources on creating an online profile (i.e. LinkedIn, Twitter, etc.) USEC will host a monthly workshop where members can meet in person and discuss SWE careers.
                </ListItem>
                </Stack>
              </List>
              <Image mt='-18.5%' ml='38rem' width='20.5rem' height='13rem' src='./CareerDev.webp' />
          </TabPanel>
          <TabPanel>
            <List styleType="disc" fontSize='1xl'>
                <Stack width='30rem' mt='2rem' spacing={4}>
                <ListItem>
                   USEC will have book club meetings every month. Members interested in committing to a book can meet with like-minded members on whatever schedule they want.
                </ListItem>
                <ListItem>
                   USEC will also have a 'recommended' book library, featuring books revolving around various aspects of software engineering and design (e.g. UI/UX, books upon specific programming languages, etc.)
                </ListItem>
                </Stack>
              </List>
              <Image mt='-12rem' ml='37rem' width='20.5rem' height='13rem' src='./book2.png' />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  )
}