import * as React from "react"
import {
    Avatar,
    Box, Center, Flex, Image, Stack, useColorModeValue,
    VStack, Text, Button, Heading, HStack, Link, Popover, PopoverTrigger,
    PopoverBody, PopoverCloseButton, PopoverHeader, PopoverContent, PopoverArrow
} from "@chakra-ui/react"
import {BiParagraph, FaGithub, FaLinkedin} from "react-icons/all";
import {useParams} from "react-router-dom";
import {UsersAPI} from "../../APIs/UsersAPI";
import {ObjectID} from "bson";
import {useEffect, useState} from "react";
import {User} from "../../Models/User";

export const MemberDashboard = () => {

    let {user_id} = useParams();
    const [user, setUser] = useState<User>();

    const get_user = () => {
        UsersAPI.get_user(ObjectID.createFromHexString(user_id!))
            .then((res) => {
                let data = res!
                setUser(data)
            })
    }

    useEffect(() => {
        get_user();
    }, [])

    return (
        <Center py={6}>
            <Box
                maxW={'400px'}
                minW={'200px'}
                w={'full'}
                bg={useColorModeValue('white', 'gray.800')}
                boxShadow={'2xl'}
                rounded={'md'}
                overflow={'hidden'}>
                <Image
                    h={'120px'}
                    w={'full'}
                    src={
                        'https://media.licdn.com/dms/image/C4E16AQFYZRYm2QaofQ/profile-displaybackgroundimage-shrink_350_1400/0/1652067368997?e=1678924800&v=beta&t=uLOKtr0XYY3X14JTspysBICVus41FonLYr0c8cpwfOY'
                    }
                    objectFit={'cover'}
                />
                <Flex justify={'center'} mt={-12}>
                    <Avatar
                        size={'xl'}
                        src={
                            user?.profile.profile_picture
                        }
                        css={{
                            border: '2px solid white',
                        }}
                    />
                </Flex>

                <Box p={6}>
                    <Stack spacing={0} align={'center'} mb={5}>
                        <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
                            {user?.profile.first_name + " " + user?.profile.last_name}
                        </Heading>
                        <Text color={'gray.500'}>{user?.profile.usec_position}</Text>
                        <Popover>
                            <PopoverTrigger>
                                <Button>Biography</Button>
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow/>
                                <PopoverCloseButton/>
                                <PopoverHeader>Biography!</PopoverHeader>
                                <PopoverBody>{user?.profile.bio}</PopoverBody>
                            </PopoverContent>
                        </Popover>
                        <Popover>
                            <PopoverTrigger>
                                <Button>Email</Button>
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow/>
                                <PopoverCloseButton/>
                                <PopoverHeader>Email!</PopoverHeader>
                                <PopoverBody>{user?.profile.personal_email}</PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </Stack>

                    <HStack>

                        {/*<Button colorScheme='linkedin' leftIcon={<FaLinkedin/>}>*/}
                        {/*    <Link href={"https://www.linkedin.com/in/yazan10x/"}>LinkedIn</Link>*/}
                        {/*</Button>*/}
                        {/*<Button color='BlackAlpha 100' leftIcon={<FaGithub/>}>*/}
                        {/*    <Link href={"https://github.com/yazan10x"}>GitHub</Link>*/}
                        {/*</Button>*/}

                    </HStack>
                </Box>
            </Box>
        </Center>
    )
    }
