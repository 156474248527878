
import * as React from "react"
import {
    Box,
    VStack,
    Grid,
    GridItem,
    SimpleGrid,
    Text,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Modal,
    useToast,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Stack,
    Skeleton, useColorModeValue, FormControl, FormLabel, Input, HStack, Textarea
} from "@chakra-ui/react"
import {User, NewUser} from "../../Models/User";
import {UsersAPI} from "../../APIs/UsersAPI";
import {useEffect} from "react";


interface CreateUserDialogCard {
    isOpen: boolean
    onClose: any
    cancelRef: any
}


export const UserSignUp = React.memo(({isOpen, onClose, cancelRef}: CreateUserDialogCard) => {
    const toast = useToast()
    const [loading, set_loading] = React.useState<boolean>(false);

    const [user, set_user] = React.useState<NewUser>(
        {
            first_name: "",
            last_name: "",
            personal_email: "",
            bio: "",
            linkedin: "",
            uoft_email: "",
        }
    );

    const required_info = () => {
        return user.first_name != "" && user.last_name != "" &&
                user.personal_email != ""
    }

    const create = () => {
        if(required_info()){
            set_loading(true)
            UsersAPI.create_user(user)
                .then()
                .finally(() => {
                    cancel()
                })
            set_loading(false)

        }else{
            toast({
                title: 'Error.',
                description: "Please fill in all the required information",
                status: 'error',
                duration: 5000,
                isClosable: true,
              })
        }
    }

    const cancel = () => {
        set_user(
            {
                first_name: "",
                last_name: "",
                personal_email: "",
                bio: "",
                linkedin: "",
                uoft_email: "",
            })
        onClose()
    }

    return (
        <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Signup
            </AlertDialogHeader>

            <AlertDialogBody>
              {loading ?
                <Stack>
                  <Skeleton height='20px' />
                  <Skeleton height='20px' />
                  <Skeleton height='20px' />
                </Stack>
                :
                <>
                  <CreateUserDialog user={user} set_user={set_user}/>
                </>
              }
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={cancel} isDisabled={loading}>
                Cancel
              </Button>
              <Button colorScheme='green' onClick={create} ml={3} isDisabled={loading}>
                Create
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    )

})


interface CreateUserProps {
    user: NewUser,
    set_user: React.Dispatch<React.SetStateAction<NewUser | undefined>> | React.Dispatch<React.SetStateAction<NewUser>>
}

export const CreateUserDialog = ({user, set_user} : CreateUserProps) =>  {

    const update_user_state = (name: string, value: any) => {
        set_user(
            (prevState: any) =>
                ({...prevState,
                [name]: value})
        );
    }

    useEffect(() => {
    }, [])

    return (
        <Stack>
            <Box
                as="form"
                bg="bg-surface"
                boxShadow={useColorModeValue('sm', 'sm-dark')}
                borderRadius="lg"
                flex="1"
            >
                <Stack px={{ base: '4'}}>
                    <br></br>
                    <VStack spacing="2" direction={{ base: 'column', md: 'row' }}>
                        <HStack>
                            <FormControl id="first_name" isRequired>
                                <FormLabel>First Name</FormLabel>
                                <Input
                                    placeholder={"Yazan"}
                                    value={user.first_name}
                                    onChange={(value) => {
                                        update_user_state(value.target.id, value.target.value)
                                    }}
                                />
                            </FormControl>
                            <FormControl id="last_name" isRequired>
                                <FormLabel>Last Name</FormLabel>
                                <Input
                                    placeholder={"Armoush"}
                                    value={user.last_name}
                                    onChange={(value) => {
                                        update_user_state(value.target.id, value.target.value)
                                    }}
                                />
                            </FormControl>
                        </HStack>
                        <FormControl id="personal_email" isRequired>
                                <FormLabel>Personal Email</FormLabel>
                                <Input
                                    placeholder={"name@example.com"}
                                    value={user.personal_email}
                                    onChange={(value) => {
                                        update_user_state(value.target.id, value.target.value)
                                    }}
                                />
                        </FormControl>
                        <FormControl id="uoft_email" isRequired>
                                <FormLabel>UofT Email</FormLabel>
                                <Input
                                    placeholder={"name@utoronto.ca"}
                                    value={user.uoft_email}
                                    onChange={(value) => {
                                        update_user_state(value.target.id, value.target.value)
                                    }}
                                />
                        </FormControl>
                        <FormControl id="linkedin" isRequired>
                                <FormLabel>LinkedIn</FormLabel>
                                <Input
                                    placeholder={"https://www.linkedin.com/in/yazanarmoush/"}
                                    value={user.linkedin}
                                    onChange={(value) => {
                                        update_user_state(value.target.id, value.target.value)
                                    }}
                                />
                        </FormControl>
                        <FormControl id="bio" isRequired>
                                <FormLabel>Bio</FormLabel>
                                <Textarea
                                    value={user.bio}
                                    onChange={(value) => {
                                        update_user_state(value.target.id, value.target.value)
                                    }}
                                    placeholder='Write a brief biography about your self'
                                    size='sm'
                                />
                        </FormControl>
                    </VStack>
                    <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>

                    </Stack>
                </Stack>
            </Box>
        </Stack>
    )

}
