import * as React from "react"
import {Box, Heading, Text, Button, Center, Container, Spacer, VStack} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function PrivacyPolicy() {
    let navigate = useNavigate()

    const handle_privacy_click = () => {
        window.open("https://openpd.org/", '_blank')!.focus();
    }

    const handle_home = () => {
        navigate("/");
    }

  return (
      <Container>
      <Center>
        <Box textAlign="center" py={10} px={6}>
        <Heading
            display="inline-block"
            as="h2"
            size="2xl"
            bgGradient="linear(to-r, teal.400, teal.600)"
            backgroundClip="text">
            Privacy Policy
        </Heading>
        <Text fontSize="18px" mt={3} mb={2}>
            UofT's Software Engineering Club Privacy Policy
        </Text>
        <VStack spacing={"10"}>
            <Spacer></Spacer>
            <Button
                colorScheme="teal"
                bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                color="white"
                variant="solid"
                onClick={handle_home}>
                Go to Home
            </Button>
            <Button
                colorScheme="purple"
                bgGradient="linear(to-r, purple.400, purple.500, purple.600)"
                color="white"
                variant="solid"
                onClick={handle_privacy_click}>
                Privacy Policy: OpenPD (AA)
            </Button>
        </VStack>
        </Box>
    </Center>
    </Container>
  );
}
