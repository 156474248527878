import * as React from "react"
import {
  ChakraProvider,
  extendTheme, Container
} from "@chakra-ui/react"

import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import {Home} from "../Components/Home/Home";
import {StaffPage} from "../Components/Users/StaffPage";
import {MembersTable} from "../Components/Users/MembersTable";
import {Dashboard} from "./Dashboard";
import NotFound from "../Components/Other/NotFound";
import ComingSoon from "../Components/Other/ComingSoon";
import PrivacyPolicy from "../Components/Other/PrivacyPolicy";
import {MemberDashboard} from "../Components/Users/MemberDashboard";
import CarbonTrack from "../Projects/CarbonTrack";




function NavigateFunctionComponent(props:any) {
  let navigate = useNavigate();
  return <></>;
}

const theme = extendTheme(
  {
    fonts: {
      heading: 'Roboto, -apple-system, system-ui, sans-serif',
      body: 'Roboto, -apple-system, system-ui, sans-serif',
    },
  },
)

export const App: React.FC = () =>  {

  return (
  <>
  <ChakraProvider theme={theme}>
        <BrowserRouter>
           {<NavigateFunctionComponent />}
            <Routes>

                <Route path="/" element={<Dashboard/>}>
                    <Route path="/home" element={<Home></Home>} />
                    <Route path="/staff" element={<StaffPage></StaffPage>}/>
                    <Route path="/members" element={<ComingSoon></ComingSoon>}/>
                    <Route path="/events" element={<ComingSoon></ComingSoon>}/>
                        <Route path="/members/:user_id" element={<MemberDashboard></MemberDashboard>}/>

                    <Route path="/events" element={<ComingSoon></ComingSoon>}/>
                    <Route path="/privacy" element={<PrivacyPolicy></PrivacyPolicy>}/>
                </Route>

                <Route path="/projects/carbon-track" element={<CarbonTrack/>}/>

                <Route path="*" element={<NotFound/>}/>

            </Routes>
        </BrowserRouter>
    </ChakraProvider>
         { /* <Container maxW='full' w='100%' h='70rem' mt='-25.5rem' background="linear-gradient(180deg, #000000 0%, #4B0082 70%, #17B169 100%)"></Container> */}
    </>
  )
}
