import FLASK_HTTPS from "./FLASK_API";
import {ObjectID} from "bson";
import {NewUser, User} from "../Models/User";


export namespace UsersAPI {

    let route_name = "/users"

    export const get_user = async (user_id: ObjectID) => {
        return FLASK_HTTPS.get(route_name + "/get_user/" + user_id.toString())
            .then((res) => {
                return res.data as User
            })
            .catch((res) => {
                console.log(res)
            })
    }

    export const get_users = async () => {
        return FLASK_HTTPS.get(route_name + "/get_users")
            .then((res) => {
                return res.data as Array<User>
            }).catch((res) => {
                console.log(res)
            })
    }

    export const create_user = async (user: NewUser) => {
        return FLASK_HTTPS.post(route_name + "/create_user",
            {
                first_name: user.first_name,
                last_name: user.last_name,
                personal_email: user.personal_email,
                bio: user.bio,
                linkedin: user.linkedin,
                uoft_email: user.uoft_email,
            })
            .then((res) => {
                return res.data as User
            }).catch((res) => {
                console.log(res)
            })
    }

}
