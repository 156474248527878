import * as React from "react"
import {
    Avatar,
    Box, Center, Flex, Image, Stack, useColorModeValue,
    VStack, Text, Button, Heading, HStack, Link, Popover, PopoverTrigger,
    PopoverBody, PopoverCloseButton, PopoverHeader, PopoverContent, PopoverArrow
} from "@chakra-ui/react"
import {BiParagraph, FaGithub, FaLinkedin} from "react-icons/all";

export const QaisCard = () => (
    <Center py={6}>
      <Box
        maxW={'400px'}
        minW={'200px'}
        w={'full'}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}>
        <Image
          h={'120px'}
          w={'full'}
          src={
            'https://media.istockphoto.com/photos/decorative-abstract-fragility-blue-shade-wavy-background-picture-id1398788867?b=1&k=20&m=1398788867&s=170667a&w=0&h=wA4Ldsi6j1owDTMkmKxf6FSrn6tshFxffwNRs71blI8='
          }
          objectFit={'cover'}
        />
        <Flex justify={'center'} mt={-12}>
          <Avatar
            size={'xl'}
            src={
              'https://media.licdn.com/dms/image/C5603AQGOdFvHPGO0Lw/profile-displayphoto-shrink_400_400/0/1656004067996?e=1702512000&v=beta&t=DPBLoDpyGxD0T71fiz0S67b1Q1hOe5gXDxZXkC7iyBs'
            }
            css={{
              border: '2px solid white',
            }}
          />
        </Flex>

        <Box p={6}>
          <Stack spacing={0} align={'center'} mb={5}>
            <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
              Qais Alkhatib
            </Heading>
            <Text color={'gray.500'}>Code Of Conduct VP</Text>
              <Popover>
                  <PopoverTrigger>
                    <Button>Biography</Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>Biography!</PopoverHeader>
                    <PopoverBody>""</PopoverBody>
                  </PopoverContent>
                </Popover>
          </Stack>

            <HStack>
              <Button colorScheme='linkedin' leftIcon={<FaLinkedin />}>
                <Link href={"https://www.linkedin.com/in/qais-alkhatib-973845239/"}>LinkedIn</Link>
              </Button>
              <Button color='BlackAlpha 100' leftIcon={<FaGithub />}>
                <Link href={"https://github.com/QA1S"}>GitHub</Link>
              </Button>
            </HStack>
        </Box>
      </Box>
    </Center>
)
