import * as React from "react"
import {
    Avatar,
    Box, Center, Flex, Image, Stack, useColorModeValue,
    VStack, Text, Button, Heading, HStack, Link, Popover, PopoverTrigger,
    PopoverBody, PopoverCloseButton, PopoverHeader, PopoverContent, PopoverArrow
} from "@chakra-ui/react"
import {BiParagraph, FaGithub, FaLinkedin} from "react-icons/all";

export const YazanCard = () => (
    <Center py={6}>
      <Box
        maxW={'400px'}
        minW={'200px'}
        w={'full'}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}>
        <Image
          h={'120px'}
          w={'full'}
          src={
            'https://media.licdn.com/dms/image/C4E16AQFYZRYm2QaofQ/profile-displaybackgroundimage-shrink_350_1400/0/1652067368997?e=1702512000&v=beta&t=5xaSkUtuFuNgKRsTUsfMCYrEtbWsH0fKQLHD5FqHH-8'
          }
          objectFit={'cover'}
        />
        <Flex justify={'center'} mt={-12}>
          <Avatar
            size={'xl'}
            src={
              'https://media.licdn.com/dms/image/D5635AQExfzTzA2eomw/profile-framedphoto-shrink_400_400/0/1693535554898?e=1697925600&v=beta&t=rU2yOvXNuxkMpjxcj3Fe7LIMi-IaMUqy8NqcsjrotrI'
            }
            css={{
              border: '2px solid white',
            }}
          />
        </Flex>

        <Box p={6}>
          <Stack spacing={0} align={'center'} mb={5}>
            <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
              Yazan Armoush
            </Heading>
            <Text color={'gray.500'}>President</Text>
              <Popover>
                  <PopoverTrigger>
                    <Button>Biography</Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>Biography!</PopoverHeader>
                    <PopoverBody>Yazan Armoush is the author of the bassemPy PDF library. The library is used to generate report cards, student cards, office cards or other PDFs that are used in primary and secondary schools. Yazan is also a second-year student specializing in computer science at the University of Toronto, where he is also the president of UofT Software Engineering Club (USEC).</PopoverBody>
                  </PopoverContent>
                </Popover>
          </Stack>

            <HStack>
              <Button colorScheme='linkedin' leftIcon={<FaLinkedin />}>
                <Link href={"https://www.linkedin.com/in/yazan10x/"}>LinkedIn</Link>
              </Button>
              <Button color='BlackAlpha 100' leftIcon={<FaGithub />}>
                <Link href={"https://github.com/yazan10x"}>GitHub</Link>
              </Button>
            </HStack>
        </Box>
      </Box>
    </Center>
)
